import { handlers } from "./helpers";
var subscribedScrolls = {};
var scrollSusbcriptionHandler = function (elementId, app) {
    function Handler(elementId) {
        var timeout;
        var start = null;
        var scrollStarted = false;
        var lastKnownScroll = { scrollTop: 0, scrollLeft: 0 };
        var debounce = { start: null, fn: false };
        function checkScrollPosition(timestamp) {
            if (!start)
                start = timestamp;
            if (timestamp - start > 100) {
                start = timestamp;
                var element_1 = document.getElementById(elementId);
                if (typeof element_1 !== "undefined" && element_1) {
                    if (lastKnownScroll.scrollTop !== element_1.scrollTop ||
                        lastKnownScroll.scrollLeft !== element_1.scrollLeft) {
                        if (scrollStarted === false) {
                            app.ports.onScrollStart_ &&
                                app.ports.onScrollStart_.send(elementId);
                            scrollStarted = true;
                        }
                        debounce.start = timestamp;
                        // @ts-ignore
                        debounce.fn = function () {
                            app.ports.onScrollEnd_ &&
                                app.ports.onScrollEnd_.send([
                                    elementId,
                                    // @ts-ignore
                                    parseInt(element_1.scrollTop),
                                    // @ts-ignore
                                    parseInt(element_1.scrollLeft)
                                ]);
                            scrollStarted = false;
                        };
                    }
                    lastKnownScroll.scrollTop = element_1.scrollTop;
                    lastKnownScroll.scrollLeft = element_1.scrollLeft;
                    if (debounce.fn !== false &&
                        timestamp - debounce.start > 100) {
                        // @ts-ignore
                        debounce.fn();
                        debounce.fn = false;
                    }
                }
            }
            window.requestAnimationFrame(checkScrollPosition);
        }
        window.requestAnimationFrame(checkScrollPosition);
    }
    subscribedScrolls[elementId] = new Handler(elementId);
};
// needs `onScrollEnd_ : (... -> msg) -> Sub msg` and `onScrollStart_ : (.. -> msg) -> Sub msg`
var debouncedScrollEvent = function (app) { return function (elementId) {
    if (typeof subscribedScrolls[elementId] === "undefined") {
        scrollSusbcriptionHandler(elementId, app);
    }
}; };
export default handlers({
    debouncedScrollEvent: debouncedScrollEvent
});
