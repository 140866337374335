var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as helpers from './helpers';
import Cookies from 'js-cookie';
var redirectToLogin = function (ENV, code) {
    var host = helpers.susiHost(helpers.getEnvironment(ENV));
    window.location.href = "".concat(host, "/?return_to=").concat(encodeURIComponent(location.href), "&code=").concat(code);
};
var redirectToRMP = function (ENV, token) {
    var host = helpers.rmpPanelHost(helpers.getEnvironment(ENV));
    window.location.href = "".concat(host, "/?access_token=").concat(token);
};
var getToken = function (ENV) {
    var params = new URLSearchParams(window.location.search);
    var urlToken = params.get(helpers.authTokenUrlParam);
    if (urlToken) {
        helpers.removeUrlParam(helpers.authTokenUrlParam);
        return { token: urlToken, tokenWasFromUrl: true };
    }
    var environment = helpers.getEnvironment(ENV);
    var masqueradingToken = Cookies.get(helpers.authMasqueradingTokenCookieName(environment));
    if (masqueradingToken) {
        return { token: masqueradingToken, tokenWasFromUrl: false };
    }
    var normalToken = Cookies.get(helpers.authTokenCookieName(environment));
    if (normalToken) {
        return { token: normalToken, tokenWasFromUrl: false };
    }
    // we have no token at all!
    redirectToLogin(ENV, 0);
    return { token: '', tokenWasFromUrl: false };
};
var getRefreshUrlToken = function () {
    var params = new URLSearchParams(window.location.search);
    var urlToken = params.get(helpers.refreshTokenUrlParam);
    if (urlToken) {
        helpers.removeUrlParam(helpers.refreshTokenUrlParam);
    }
    return urlToken;
};
var isLocalDevelopment = function (ENV) {
    var environment = helpers.getEnvironment(ENV);
    return environment === 'development' || window.location.hostname.includes('localhost');
};
export default (function (ENV) { return __awaiter(void 0, void 0, void 0, function () {
    var refreshToken, _a, token, tokenWasFromUrl, environment, userResponse, user, organisationResponse, organisation;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                refreshToken = getRefreshUrlToken();
                _a = getToken(ENV), token = _a.token, tokenWasFromUrl = _a.tokenWasFromUrl;
                environment = helpers.getEnvironment(ENV);
                if (refreshToken && isLocalDevelopment(ENV)) {
                    Cookies.set(helpers.refreshTokenCookieName(environment), refreshToken, {
                        sameSite: 'strict',
                        secure: false,
                        expires: 1
                    });
                }
                return [4 /*yield*/, fetch(helpers.host(environment) + '/api/current_user', {
                        method: 'GET',
                        headers: { Authorization: "Bearer ".concat(token) }
                    })];
            case 1:
                userResponse = _b.sent();
                if (!userResponse.ok) {
                    // token was invalid / expired / ...
                    redirectToLogin(ENV, userResponse.status);
                }
                return [4 /*yield*/, userResponse.json()];
            case 2:
                user = (_b.sent()).data;
                return [4 /*yield*/, fetch(helpers.host(environment) + "/v1/organisations/users/".concat(user.id, "/organisation"), {
                        method: 'GET',
                        headers: { Authorization: "Bearer ".concat(token) }
                    })];
            case 3:
                organisationResponse = _b.sent();
                return [4 /*yield*/, organisationResponse.json()];
            case 4:
                organisation = (_b.sent()).organisation;
                if (user.customer_features.includes('RMP Panel Dashboard (Panel users only)')) {
                    redirectToRMP(ENV, token);
                }
                if (ENV.is_headless_test) {
                    // disable the "sticky P2" dialog for tests
                    user.last_platform_used = 'platform1';
                }
                if (tokenWasFromUrl && isLocalDevelopment(ENV)) {
                    // On localhost:3000, this is the only way to login.
                    // We need to set a cookie ourselves (SUSI can't do that for us).
                    Cookies.set(helpers.authTokenCookieName(environment), token, {
                        sameSite: 'strict',
                        secure: false,
                        expires: 1
                    });
                }
                /* The app expects the organisation info living inside the user info, for
                 * legacy reasons. The backends have split though so our frontend asks both to
                 * keep things as before.
                 */
                ENV.user = __assign(__assign({}, user), { organisation_id: organisation.id, organisation_name: organisation.name });
                ENV.token = token;
                // @ts-ignore
                if (!!window.Sentry) {
                    // @ts-ignore
                    Sentry.setUser({ id: user.id });
                    // @ts-ignore
                    Sentry.configureScope(function (scope) {
                        scope.setTag('plan', user.plan_handle);
                        scope.setExtra('customer_features', user.customer_features);
                    });
                }
                return [2 /*return*/, ENV];
        }
    });
}); });
