import * as helpers from "./helpers";
import Cookies from "js-cookie";
var MISSING_REFRESH_TOKEN_ERROR = "MISSING_REFRESH_TOKEN_ERROR";
var parseJWT = function (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(atob(base64)
        .split("")
        .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(""));
    return JSON.parse(jsonPayload);
};
var getExpirationFromToken = function (token) {
    var parsed = parseJWT(token);
    return new Date(parsed.exp * 1000);
};
var setCookie = function (ENV, name, value) {
    var options = {};
    if (helpers.getEnvironment(ENV) === "development") {
        options = {
            sameSite: "strict",
            secure: false,
            expires: 1
        };
    }
    else {
        options = { domain: helpers.domain, expires: 1 };
    }
    Cookies.set(name, value, options);
};
var refreshToken = function (app, ENV) {
    var environment = helpers.getEnvironment(ENV);
    var currentRefreshToken = Cookies.get(helpers.refreshTokenCookieName(environment));
    if (!currentRefreshToken) {
        return new Promise(function (resolve) {
            throw new Error(MISSING_REFRESH_TOKEN_ERROR);
        });
    }
    return fetch(helpers.host(environment) + "/v1/users/refresh_token", {
        method: "POST",
        headers: {
            Authorization: "Basic ".concat(currentRefreshToken)
        }
    })
        .then(function (response) {
        if (response.ok) {
            return response.json();
        }
        else {
            return { error: response.json(), status: response.status };
        }
    })
        .then(function (decoded) {
        if (decoded.access_token) {
            setCookie(ENV, helpers.refreshTokenCookieName(environment), decoded.refresh_token);
            setCookie(ENV, helpers.authTokenCookieName(environment), decoded.access_token);
            app.ports.setToken.send(decoded.access_token);
        }
        return decoded;
    });
};
var getToken = function (ENV) {
    return Cookies.get(helpers.authTokenCookieName(helpers.getEnvironment(ENV)));
};
export var silentTokenRefresh = function (app, ENV) {
    var checkExpirationFrequency = 10 * 1000;
    var tokenExpireAt = new Date();
    var token = getToken(ENV);
    if (token) {
        tokenExpireAt = getExpirationFromToken(token);
    }
    var checkExpiration = function () {
        var dateToCheck = new Date();
        //Expires in next 10 minutes?
        dateToCheck.setMinutes(dateToCheck.getMinutes() + 10);
        if (dateToCheck.getTime() >= tokenExpireAt.getTime()) {
            refreshToken(app, ENV).then(function (data) {
                // @ts-ignore
                if (data.access_token) {
                    tokenExpireAt = getExpirationFromToken(
                    // @ts-ignore
                    data.access_token);
                    setTimeout(checkExpiration, checkExpirationFrequency);
                }
            }, function (error) {
                //we can wait for next attempt, but if refresh token is missing we can't do much
                if (error.message !== MISSING_REFRESH_TOKEN_ERROR) {
                    setTimeout(checkExpiration, checkExpirationFrequency);
                }
            });
        }
        else {
            setTimeout(checkExpiration, checkExpirationFrequency);
        }
    };
    checkExpiration();
};
