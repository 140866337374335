var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as helpers from "./helpers";
import debounce from "debounce";
import Bowser from "bowser";
import { p2EnvPlatform } from "../_helpers/platform";
var identify = function (ENV) {
    if (ENV.is_headless_test)
        return;
    if (ENV.user.plan_handle === "open_access_view_only")
        return;
    var body = JSON.stringify({
        traits: { proPlan: ENV.user.plan_handle },
        app_name: ENV.app_name,
        user_id: ENV.user.id,
        user_email: ENV.user.email
    });
    fetch(helpers.host(helpers.getEnvironment(ENV)) +
        "/v1/analytics/identify", {
        method: "POST",
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(ENV.token),
            "Keep-Alive": "timeout=10, max=1"
        }
    });
};
var sendLastPlatformUsed = debounce(function (ENV) {
    return fetch("".concat(helpers.host(helpers.getEnvironment(ENV)), "/v1/users-next/users/").concat(ENV.user.id, "/last_platform_used"), {
        method: "PUT",
        body: JSON.stringify({
            platform: ENV.platform === p2EnvPlatform
                ? "platform2"
                : "platform1"
        }),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(ENV.token),
            "Keep-Alive": "timeout=10, max=1"
        }
    });
}, 60 * 1000, true);
var nullAsEmptyString = function (v) { return (v === null ? "" : v); };
var extendedProperties = function (basicProperties, ENV) {
    var _a, _b, _c, _d;
    var browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
    return __assign(__assign({}, basicProperties), { plan_handle_snapshot: ENV.user.plan_handle, access_end_snapshot: nullAsEmptyString(ENV.user.access_end), app_user_id_snapshot: ENV.user.id, customer_features_snapshot: ENV.user.customer_features, job_title_snapshot: ENV.user.job_title, organisation_name_snapshot: ENV.user.organisation_name, user_agent: window.navigator.userAgent, browser_name: browser.name, browser_version: browser.version, floc_id: (_b = (_a = ENV.cohort) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null, floc_version: (_d = (_c = ENV.cohort) === null || _c === void 0 ? void 0 : _c.version) !== null && _d !== void 0 ? _d : null, screen_height: window.screen.height, screen_width: window.screen.width });
};
var track = function (ENV, eventName, properties) {
    if (properties === void 0) { properties = {}; }
    if (ENV.is_headless_test)
        return;
    if (ENV.user.plan_handle === "open_access_view_only")
        return;
    var body = JSON.stringify({
        traits: {},
        app_name: ENV.app_name,
        user_id: ENV.user.id,
        user_email: ENV.user.email,
        event_name: eventName,
        properties: extendedProperties(properties, ENV)
    });
    fetch(helpers.host(helpers.getEnvironment(ENV)) + "/v1/analytics/track", {
        method: "POST",
        body: body,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(ENV.token),
            "Keep-Alive": "timeout=10, max=1"
        }
    });
    sendLastPlatformUsed(ENV);
};
var batch = function (ENV, events) {
    if (ENV.is_headless_test)
        return;
    if (ENV.user.plan_handle === "open_access_view_only")
        return;
    var body = JSON.stringify(events.map(function (event) {
        var eventName = event[0];
        var properties = event[1];
        return {
            type: "track",
            traits: {},
            app_name: ENV.app_name,
            user_id: ENV.user.id,
            user_email: ENV.user.email,
            event_name: eventName,
            properties: extendedProperties(properties, ENV)
        };
    }));
    fetch(helpers.host(helpers.getEnvironment(ENV)) + "/v1/analytics/batch", {
        method: "POST",
        body: body,
        headers: {
            "Content-Type": "application/json",
            "Keep-Alive": "timeout=10, max=1",
            Authorization: "Bearer ".concat(ENV.token)
        }
    }).catch(function (e) {
        console.error("Error fetching analytics", e, events);
    });
    sendLastPlatformUsed(ENV);
};
export default (function (ENV) {
    return new Promise(function (resolve) {
        identify(ENV);
        // @ts-ignore
        window.analytics = {
            track: track.bind(null, ENV),
            batch: batch.bind(null, ENV)
        };
        resolve(ENV);
    });
});
