var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import d3 from "d3";
import d3scription from "d3scription-fork";
import { register } from "../../_helpers/webcomponents";
function initObservers() {
    var _this = this;
    this.tipShown = false;
    this.isOverForbidden = false;
    this.removeObservers = null;
    // @ts-ignore
    var tipFactory = d3scription(function (msg) { return msg; }, {
        class: "d3scription-tip dashboard-tip" +
            "".concat(this.darkMode ? " dark-mode" : "")
    });
    var content = this.getAttribute("content");
    if (content !== undefined) {
        this.content = content;
    }
    var els = document.querySelectorAll(this.observedSelector);
    var removeObservers = [];
    var _loop_1 = function (i) {
        var el = els[i];
        if (!this_1.tip) {
            this_1.tip = tipFactory().element(d3.select(el));
        }
        var mouseMoveObserver = function (e) {
            if (_this.isOverForbidden)
                return;
            if (_this.tip && !_this.tipShown && _this.content && d3) {
                // @ts-ignore d3.event is not linked correctly within d3 itself
                d3.event = e;
                _this.tip.element(d3.select(el));
                _this.tip.show(_this.content);
                _this.tipShown = true;
            }
            else if (_this.tip && _this.tipShown && _this.content && d3) {
                _this.tip.update(_this.content);
            }
        };
        el.addEventListener("mousemove", mouseMoveObserver);
        removeObservers.push(function () { return el.removeEventListener("mousemove", mouseMoveObserver); });
        var mouseOutObserver = function (e) {
            if (_this.tip && _this.tipShown) {
                _this.tip.hide();
                _this.tipShown = false;
            }
        };
        el.addEventListener("mouseout", mouseOutObserver);
        removeObservers.push(function () { return el.removeEventListener("mouseout", mouseOutObserver); });
    };
    var this_1 = this;
    for (var i = 0; i < els.length; i++) {
        _loop_1(i);
    }
    if (this.forbiddenSelector &&
        document.querySelector(this.forbiddenSelector)) {
        var forbiddenSelectorElement_1 = document.querySelector(this.forbiddenSelector);
        var forbiddenSelectorEvent_1 = function () {
            if (_this.tip) {
                _this.tip.hide();
                _this.tipShown = false;
                _this.isOverForbidden = true;
            }
        };
        var forbiddenSelectorOutEvent_1 = function () {
            _this.isOverForbidden = false;
        };
        forbiddenSelectorElement_1.addEventListener("mouseover", forbiddenSelectorEvent_1);
        forbiddenSelectorElement_1.addEventListener("mouseout", forbiddenSelectorOutEvent_1);
        removeObservers.push(function () {
            forbiddenSelectorElement_1.removeEventListener("mouseover", forbiddenSelectorEvent_1);
            forbiddenSelectorElement_1.removeEventListener("mouseout", forbiddenSelectorOutEvent_1);
        });
    }
    this.removeObservers = function () {
        for (var _i = 0, removeObservers_1 = removeObservers; _i < removeObservers_1.length; _i++) {
            var fn = removeObservers_1[_i];
            if (typeof fn === "function") {
                fn();
            }
        }
    };
}
/**
 * <x-d3scription target-selector=".my-target" content="<b>Content</b>">
 *   <!-- whatever HTML you put inside -->
 *   <div>
 *     ...
 *     <div class="my-target"> ... </div>
 *     ...
 *   </div>
 * </x-d3scription>
 *
 * will show `content` on hovering over `target-selector` as tooltip
 */
var XD3scription = /** @class */ (function (_super) {
    __extends(XD3scription, _super);
    function XD3scription() {
        var _this = _super.call(this) || this;
        _this.darkMode = false;
        return _this;
    }
    Object.defineProperty(XD3scription, "observedAttributes", {
        get: function () {
            return [
                "content",
                "dark-mode",
                "target-selector",
                "forbidden-selector",
                "reinit-trigger"
            ];
        },
        enumerable: false,
        configurable: true
    });
    XD3scription.prototype.connectedCallback = function () {
        initObservers.call(this);
    };
    XD3scription.prototype.disconnectedCallback = function () {
        this.tipShown = false;
        this.tip.remove();
        if (this.removeObservers) {
            this.removeObservers();
        }
    };
    XD3scription.prototype.attributeChangedCallback = function (attrName, oldValue, newValue) {
        if (attrName === "target-selector" && newValue) {
            this.observedSelector = newValue;
        }
        if (attrName === "content" && newValue) {
            this.content = newValue;
        }
        if (attrName === "dark-mode" && newValue) {
            this.darkMode = newValue;
        }
        if (attrName === "forbidden-selector" && newValue) {
            this.forbiddenSelector = newValue;
        }
        if (attrName === "reinit-trigger" && newValue) {
            if (this.removeObservers) {
                this.removeObservers();
            }
            initObservers.call(this);
        }
    };
    return XD3scription;
}(HTMLElement));
export default XD3scription;
register("x-d3scription", XD3scription);
