var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import SimpleBar from "simplebar";
import { register } from "../../_helpers/webcomponents";
var getElementWindow = function (element) {
    return !element || !element.ownerDocument || !element.ownerDocument.defaultView
        ? window
        : element.ownerDocument.defaultView;
};
var PlatformSimpleBar = /** @class */ (function (_super) {
    __extends(PlatformSimpleBar, _super);
    function PlatformSimpleBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlatformSimpleBar.prototype.getCorrectTrackSize = function (axis) {
        if (axis === "y") {
            var barStyles = getComputedStyle(document.querySelector(".simplebar-track.simplebar-vertical"));
            return (parseInt(barStyles.height, 10) + parseInt(barStyles.bottom, 10));
        }
        else {
            var barStyles = getComputedStyle(document.querySelector(".simplebar-track.simplebar-horizontal"));
            return (parseInt(barStyles.width, 10) + parseInt(barStyles.right, 10));
        }
    };
    PlatformSimpleBar.prototype.onTrackClick = function (e, axis) {
        var _this = this;
        if (axis === void 0) { axis = "y"; }
        if (!this.options.clickOnTrack)
            return;
        var elWindow = getElementWindow(this.el);
        this.axis[axis].scrollbar.rect = this.axis[axis].scrollbar.el.getBoundingClientRect();
        var scrollbar = this.axis[axis].scrollbar;
        var scrollbarOffset = scrollbar.rect[this.axis[axis].offsetAttr];
        var hostSize = this.getCorrectTrackSize(axis);
        var scrolled = this.contentWrapperEl[this.axis[axis].scrollOffsetAttr];
        var t = axis === "y"
            ? this.mouseY - scrollbarOffset
            : this.mouseX - scrollbarOffset;
        var dir = t < 0 ? -1 : 1;
        var scrollSize = dir === -1 ? scrolled - hostSize : scrolled + hostSize;
        var speed = 100;
        var scrollTo = function () {
            var _a, _b;
            if (dir === -1) {
                if (scrolled > scrollSize) {
                    scrolled -= speed;
                    _this.contentWrapperEl.scrollTo((_a = {},
                        _a[_this.axis[axis].offsetAttr] = scrolled,
                        _a));
                    elWindow.requestAnimationFrame(scrollTo);
                }
            }
            else {
                if (scrolled < scrollSize) {
                    scrolled += speed;
                    _this.contentWrapperEl.scrollTo((_b = {},
                        _b[_this.axis[axis].offsetAttr] = scrolled,
                        _b));
                    elWindow.requestAnimationFrame(scrollTo);
                }
            }
        };
        scrollTo();
    };
    PlatformSimpleBar.prototype.getScrollbarSize = function (axis) {
        if (axis === void 0) { axis = "y"; }
        if (!this.axis[axis].isOverflowing) {
            return 0;
        }
        var contentSize = this.contentEl[this.axis[axis].scrollSizeAttr];
        var trackSize = this.getCorrectTrackSize(axis);
        var scrollbarSize;
        var scrollbarRatio = trackSize / contentSize;
        // Calculate new height/position of drag handle.
        scrollbarSize = Math.max(~~(scrollbarRatio * trackSize), this.options.scrollbarMinSize);
        if (this.options.scrollbarMaxSize) {
            scrollbarSize = Math.min(scrollbarSize, this.options.scrollbarMaxSize);
        }
        return scrollbarSize;
    };
    return PlatformSimpleBar;
}(SimpleBar));
var SimpleBarComponent = /** @class */ (function (_super) {
    __extends(SimpleBarComponent, _super);
    function SimpleBarComponent() {
        var _this = _super.call(this) || this;
        _this.createSimpleBar = function () {
            var variant = this.getAttribute("variant");
            if (variant === "simple") {
                this._simplebar = new SimpleBar(this);
            }
            else {
                this._simplebar = new PlatformSimpleBar(this);
            }
        };
        return _this;
    }
    Object.defineProperty(SimpleBarComponent, "observedAttributes", {
        get: function () {
            return ["scrollable-selector", "variant"];
        },
        enumerable: false,
        configurable: true
    });
    SimpleBarComponent.prototype.connectedCallback = function () {
        var _this = this;
        /* Handle issue when simplebar is set to element and also CSS is changed by additional class in the same moment.
       Simplebar could be initialised with wrong (not CSS recomputed) state.
       This little delay gives browser time to re-render all HTML based on latest CSS changes.
    */
        setTimeout(function () { return _this.createSimpleBar(); }, 100);
    };
    SimpleBarComponent.prototype.disconnectedCallback = function () {
        if (this._simplebar) {
            this._simplebar.unMount();
            delete this._simplebar;
        }
    };
    SimpleBarComponent.prototype.attributeChangedCallback = function (attrName, oldValue, newValue) {
        if (this._simplebar === undefined) {
            return;
        }
        if (attrName === "scrollable-selector" && newValue) {
            // @ts-ignore
            this._simplebar.scrollableSelector = newValue;
        }
        else if (attrName === "variant" && oldValue !== newValue) {
            this._simplebar.unMount();
            this.createSimpleBar();
        }
    };
    return SimpleBarComponent;
}(HTMLElement));
register("x-simplebar", SimpleBarComponent);
