const d3 = require('d3');
const d3Charts = require('d3-charts');
const ResizeObserver = require('resize-observer-polyfill').default;

const {allMetrics} = require('../chart-metrics.js');

const ro = new ResizeObserver(function(entries, observer) {
    for (var k in entries) {
      if (entries.hasOwnProperty(k)) {
          var target = entries[k].target;
          if (target.redraw) {
              target.redraw.call(target);
          }
      }
    }
});


const sumLabelText = (metric) => {
  switch(metric) {
    case 'percentage':
      return 'Total Audience %';
    case 'weighted_universe_count':
      return 'Total Universe';
    default:
      return 'Total';
  }
}

/* <x-dashboards-chart
 *      chart-type="donut_chart"
 *      metric="percentage"
 *      data="..."
 *      scale-maxes="..."
 *  />
 */
xtag.register('x-dashboards-chart', {
  content: '<svg></svg>',
  lifecycle: {
    inserted: function() {
      this.init();
      this.redraw();
      ro.observe(this);
    },
    removed: function() {
      ro.unobserve(this)
      this.chart.remove();
      this.chart = null;
    },
    attributeChanged: function(attrName, _, newValue) {
      switch(attrName) {
        case 'chart-type':
          if (!this.chart) { return; }
          this.init();
          break;
        case 'data':
          this.redraw();
          break;
        case 'metric':
          this.redraw();
          break;
      }
    }
  },
  methods: {
    redraw_: function() {
      if (!this.chart) { return; }

      const metric = this.getAttribute('metric');
      const data = JSON.parse(this.getAttribute('data'));

      this.chart.data(data);

      const chartMetric = this.chart.metric(metric);
      chartMetric && chartMetric.axisLabel && chartMetric.axisLabel(this.getMetricName(metric));

      d3.select(this).select('svg').call(this.chart);
    },

    redraw: function() {
      if (this.redrawDebounce) clearTimeout(this.redrawDebounce);
      this.redrawDebounce = setTimeout(() => this.redraw_(), 100);
    },

    init: function() {

      // This function should be called whenever chart-type attribute changes
      if (this.chart) {
        this.chart.remove();
        this.chart = null;
      }

      const chartType = this.getAttribute('chart-type');
      const metric = this.getAttribute('metric');
      const data = JSON.parse(this.getAttribute('data'));
      const scaleMaxes = JSON.parse(this.getAttribute('scale-maxes'));
      let tooltipDebounce;

      // Elm has to handle this event and decide what to do about it.
      const tipHook = (state) => {
        clearTimeout(tooltipDebounce);
        if (state.isVisible) {
          tooltipDebounce = setTimeout(() => xtag.fireEvent(this, 'tracktooltipshown', {detail: state.data.name}), 1500);
        }
      };

      this.chart = d3Charts.getDashboardChart(chartType)(tipHook)
        .metric(metric)
        .scaleMaxes(scaleMaxes)
        .data(data);

      if (chartType === 'donut_chart') {
        // TODO this doesn't work
        this.chart.setSumLabel(sumLabelText(metric));
      }

    },

    getMetricName: function(id) {
      for (var i in allMetrics) {
        var metric = allMetrics[i];
        if (metric.id === id) {
          return metric.name;
        }
      }
      return;
    }
  }
});
