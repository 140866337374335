import { handlers } from "./helpers";
var dragstart = function (app) { return function (event) {
    try {
        event.dataTransfer.setData("text", "");
    }
    catch (e) {
        // @ts-ignore
        if (e instanceof NoModificationAllowedError) {
        } // sometimes happens if you try to drag too fast? dunno... ~janiczek
        else {
            throw e;
        }
    }
}; };
export default handlers({
    dragstart: dragstart
});
